import { FocusEventHandler } from "react";
import styled from "styled-components";
import { useLocalStorageState } from "./useLocalStorageState";

const Column = styled.div`
  margin: 0 auto;
  max-width: 400px;
  padding: 0 1rem;
`;

const Title = styled.h1`
  padding-top: 3rem;
`;

const Subtitle = styled.blockquote`
  margin-bottom: 3rem;
`;

const Main = styled.main``;

const Label = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: 3px double;
`;

const Input = styled.input`
  margin-left: auto;
  border: none;
  width: 5rem;
  background: transparent;
  text-align: right;

  &:focus {
    outline: 2px solid wheat;
    outline-offset: -2px;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }
`;

const Unit = styled.span``;

const Results = styled.div`
  margin-top: 3rem;
`;

function App() {
  const [levainWeight, setLevainWeight] = useLocalStorageState(
    "levainWeight",
    50
  );
  const [loavesCount, setLoavesCount] = useLocalStorageState("loavesCount", 1);
  const [flourPerLoaf, setFlourPerLoaf] = useLocalStorageState(
    "flourPerLoaf",
    500
  );
  const [hydration, setHydration] = useLocalStorageState("hydration", 70);
  const [saltPercentage, setSaltPercentage] = useLocalStorageState(
    "saltPercentage",
    2
  );

  const hydrationInLevain = levainWeight / 2;
  const flourInLevain = levainWeight / 2;

  const totalFlourNeeded = loavesCount * flourPerLoaf;
  const totalWaterNeeded = totalFlourNeeded * (hydration / 100);
  const salt = (saltPercentage / 100) * totalFlourNeeded;

  const handleFocus: FocusEventHandler<HTMLInputElement> = (e) => {
    e.currentTarget.select();
  };

  return (
    <Column>
      <Title>poolish</Title>
      <Subtitle>
        A mixture of flour and water with a little yeast; used to make some
        forms of dough.
      </Subtitle>
      <Main>
        <Label>
          Loaves
          <Input
            type="number"
            value={loavesCount}
            onChange={(e) => setLoavesCount(+e.target.value)}
            onFocus={handleFocus}
          />
        </Label>
        <Label>
          Flour per loaf
          <Input
            type="number"
            value={flourPerLoaf}
            onChange={(e) => setFlourPerLoaf(+e.target.value)}
            onFocus={handleFocus}
          />
          <Unit>g</Unit>
        </Label>
        <Label>
          Levain
          <Input
            type="number"
            value={levainWeight}
            onChange={(e) => setLevainWeight(+e.target.value)}
            onFocus={handleFocus}
          />
          <Unit>g</Unit>
        </Label>
        <Label>
          Hydration
          <Input
            type="number"
            value={hydration}
            onChange={(e) => setHydration(+e.target.value)}
            onFocus={handleFocus}
          />
          <Unit>%</Unit>
        </Label>
        <Label>
          Salt
          <Input
            type="number"
            value={saltPercentage}
            onChange={(e) => setSaltPercentage(+e.target.value)}
            onFocus={handleFocus}
          />
          <Unit>%</Unit>
        </Label>

        <Results>
          <p>Levain: {levainWeight}g</p>
          <p>Water: {Math.round(totalWaterNeeded - hydrationInLevain)}g</p>
          <p>Flour: {Math.round(totalFlourNeeded - flourInLevain)}g</p>
          <p>Salt: {Math.round(salt)}g</p>
        </Results>
      </Main>
    </Column>
  );
}

export default App;
